import { create } from "zustand";

const useChat = create((set) => ({
  session: "",
  messages: [],
  isTyping: false,
  currentBotMessage: "",
  botTyping: false,
  habilitaImagens: false,
  habilitaEmail: false,
  habilitaZendesk: false,
  habilitaContexto: true,
  habilitaVoz: false,
  engine: 'azure',
  shouldLog: true,
  newChat: null,

  setSession: (session) => set({ session }),
  setMessages: (messages) => set({ messages }),
  setIsTyping: (isTyping) => set({ isTyping }),
  setCurrentBotMessage: (currentBotMessage) =>
    set((state) => ({
      currentBotMessage:
        typeof currentBotMessage === "function"
          ? currentBotMessage(state.currentBotMessage) 
          : currentBotMessage,
    })),  
  setBotTyping: (botTyping) => set({ botTyping }),
  setHabilitaImagens: (habilitaImagens) => set({ habilitaImagens }),
  setHabilitaEmail: (habilitaEmail) => set({ habilitaEmail }),
  setHabilitaZendesk: (habilitaZendesk) => set({ habilitaZendesk }),
  setHabilitaContexto: (habilitaContexto) => set({ habilitaContexto }),
  setHabilitaVoz: (habilitaVoz) => set({ habilitaVoz }),
  setEngine: (engine) => set({ engine }),
  setShouldLog: (shouldLog) => set({ shouldLog }),
  setNewChat: (newChat) => set({newChat}),

  addMessage: (message) =>
    set((state) => ({ messages: [...state.messages, message] })),
  clearMessages: () => set({ messages: [] }),
}));

export default useChat;
