import React, { useEffect, useState, useRef } from "react";
import apiTokenConsumption from "../../services/api/apiTokenConsumption";
import evaApi from "../../services/api/evaApi";
import CardGrid from "../CardGrId/CardGrid.jsx";
import ChartSection from "../ChartSection/CharSection.jsx";
import DateForm from "../DateForm/DateForm.jsx";
import Footer from "../Footer/Footer.jsx";
import { Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import CardGridChannel from "../CardGridChannel/CardGridChannel.jsx";
import { Background } from "./Dashboard";
import useAppSession from "../../stores/useAppSession.js";
import { Space, Typography, Spin, Row, Col } from "antd";
import dayjs from "dayjs";
import { useIntl } from "react-intl";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, 
  //ChartDataLabels
);

const Dashboard = ({ onLogout }) => {
  const calculatePercentages = (channelData) => {
    const total = Object.values(channelData).reduce((sum, value) => sum + value, 0);
    const percentages = Object.values(channelData).map((value) =>
      ((value / total) * 100).toFixed(2)
    );
    return {
      labels: Object.keys(channelData),
      datasets: [
        {
          data: Object.values(channelData),
          backgroundColor: ["#336CEC", "#33CE56", "#F1C40F", "#9B59B6"],
          hoverBackgroundColor: ["#336CEC", "#33CE56", "#F1C40F", "#9B59B6"],
        },
      ],
      percentages,
    };
  };
  const { clientId, sessionId } = useAppSession();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(6, "month"));
  const [endDate, setEndDate] = useState(dayjs());
  const [messagesLength, setMessagesLength] = useState(0);
  const [messagesByChannel, setMessagesByChannel] = useState({ Web: 0, Widget: 0, Telegram: 0, Whatsapp: 0});
  const [messagesPerDayByChannel, setMessagesPerDayByChannel] = useState({});
  const [grafic, setGrafic] = useState("dia");
  const [count, setCount] = useState(0);
  const [refreshInterval, setRefreshInterval] = useState(60);
  const inactiveTimerRef = useRef(null);
  const inactivityLimit = 60 * 60 * 1000;
  const intl = useIntl();

  useEffect(() => {
    const resetTimer = () => {
      if (inactiveTimerRef.current) {
        clearTimeout(inactiveTimerRef.current);
      }
      inactiveTimerRef.current = setTimeout(() => {
        onLogout();
      }, inactivityLimit);
    };
    resetTimer();

    return () => {
      clearTimeout(inactiveTimerRef.current);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await apiTokenConsumption.get_tokens(
        clientId,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
      if (response.status === 401 || response.status === 403) {
        onLogout();
      }
      const result = response;
      setData(result.data);

      const responseMesssages = await evaApi.getMessages(
        clientId,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
      if (
        responseMesssages.status === 401 ||
        responseMesssages.status === 403
      ) {
        onLogout();
      }
      const messages = responseMesssages.data.messages;
      setMessagesLength(messages.length);

      const channelCounts = messages.reduce((acc, message) => {
        const channel = getChannelFromUsername(message.username);
        acc[channel] = (acc[channel] || 0) + 1;
        return acc;
      }, { Web: 0, Widget: 0, Telegram: 0, Whatsapp: 0 });

      setMessagesByChannel(channelCounts);

      // Calculate messages per day by channel
      const messagesPerDay = messages.reduce((acc, message) => {
        const date = message.data.split(" ")[0];
        const channel = getChannelFromUsername(message.username);
        if (!acc[date]) {
          acc[date] = { Web: 0, Widget: 0, Telegram: 0, Whatsapp: 0};
        }
        acc[date][channel] = (acc[date][channel] || 0) + 1;
        return acc;
      }, {});

      setMessagesPerDayByChannel(messagesPerDay);
    } catch (error) {
      console.error("Error fetching token consumption data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [clientId, startDate, endDate]);

  const handleRefresh = () => {
    setCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleRefresh();
    }, refreshInterval * 1000);

    return () => clearInterval(intervalId);
  }, [refreshInterval, count]);

  const getChannelFromUsername = (username) => {
    if (username.toUpperCase().includes('WEB')) return 'Web';
    if (username.toUpperCase().includes('WIDGET')) return 'Widget';
    if (username.toUpperCase().includes('TELEGRAM')) return 'Telegram';
    if (username.toUpperCase().includes('WHATSAPP')) return 'Whatsapp';
    return
  };

  if (!data)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
        <Typography.Text style={{ marginTop: "16px" }}>
          {intl.formatMessage({ id: "dashboard.loading", defaultMessage: "Carregando..." })}
        </Typography.Text>
      </div>
    );
  // Process the data for the chart
  const processedData = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.day}/${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.message_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedDataFiles = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedTotalTokens = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.message_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedSizeFiles = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_size);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedSizeMessages = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.message_size);
      return acc;
    },
    { labels: [], values: [] }
  );

  const chartDataByChannel = {
    labels: Object.keys(messagesPerDayByChannel),
    datasets: [
      {
        label: intl.formatMessage({ id: "dashboard.chart.label.web", defaultMessage: "Web" }),
        data: Object.values(messagesPerDayByChannel).map((day) => day.Web),
        borderColor: "#336CEC",
        backgroundColor: "#FF8F3F",
        tension: 0.3,
      },
      {
        label: intl.formatMessage({ id: "dashboard.chart.label.widget", defaultMessage: "Widget" }),
        data: Object.values(messagesPerDayByChannel).map((day) => day.Widget),
        borderColor: "#33CE56",
        backgroundColor: "#FF8F3F",
        tension: 0.3,
      },
      {
        label: intl.formatMessage({ id: "dashboard.chart.label.telegram", defaultMessage: "Telegram" }),
        data: Object.values(messagesPerDayByChannel).map((day) => day.Telegram),
        borderColor: "#F1C40F",
        backgroundColor: "#FF8F3F",
        tension: 0.3,
      },
      {
        label: intl.formatMessage({ id: "dashboard.chart.label.whatsapp", defaultMessage: "Whatsapp" }),
        data: Object.values(messagesPerDayByChannel).map((day) => day.Whatsapp),
        borderColor: "#9B59B6",
        backgroundColor: "#FF8F3F",
        tension: 0.3,
      },
    ],
  };

  function joinFirstAndLastEverySeven(arr) {
    const result = [];

    for (let i = 0; i < arr.length; i += 7) {
      const chunk = arr.slice(i, i + 7);
      if (chunk.length > 1) {
        result.push(`${chunk[0]} - ${chunk[chunk.length - 1]}`);
      } else if (chunk.length === 1) {
        result.push(chunk[0]);
      }
    }

    return result;
  }

  function sumEverySeven(arr) {
    const result = [];

    for (let i = 0; i < arr.length; i += 7) {
      const chunk = arr.slice(i, i + 7);
      const sum = chunk.reduce((acc, val) => acc + val, 0);
      result.push(sum);
    }

    return result;
  }

  const chartData = {
    labels:
      grafic === "dia"
        ? processedData.labels
        : joinFirstAndLastEverySeven(processedData.labels),
    datasets: [
      {
        label: "Tokens Consumed",
        data:
          grafic === "dia"
            ? processedTotalTokens.values
            : sumEverySeven(processedTotalTokens.values),
        borderColor: "rgb(79, 157, 235)",
        tension: 0.3,
      },
    ],
  };

  const totalMessagesSize = processedSizeMessages.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalTokens = processedTotalTokens.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalFilesTokens = processedDataFiles.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalFilesSize = processedSizeFiles.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const averageTokens = Math.round(totalTokens / processedData.values.length);
  const lastMonthTokens =
    processedData.values[processedData.values.length - 1] +
    processedDataFiles.values[processedDataFiles.values.length - 1];

    
  return (
    <>
      <Background>
        <Space
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <Typography.Title level={2}>
            {intl.formatMessage({ id: "dashboard.title", defaultMessage: "Dashboard" })}
          </Typography.Title>
        </Space>
        <DateForm
          startDate={startDate}
          endDate={endDate}
          onChangeStartDate={setStartDate}
          onChangeEndDate={setEndDate}
          grafic={grafic}
          setGrafic={setGrafic}
        />
        <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
          <Col span={12}>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <CardGrid
                totalTokens={totalTokens}
                totalFilesTokens={totalFilesTokens}
                lastMonthTokens={lastMonthTokens}
                totalMessages={messagesLength}
                totalFilesSize={totalFilesSize}
                totalMessagesSize={totalMessagesSize}
                averageTokens={averageTokens}
              />
              <ChartSection grafic={grafic} data={chartData} />
            </div>
          </Col>
          <Col span={12}>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <CardGridChannel messagesByChannel={messagesByChannel} />
              <div
                style={{
                  height: "400px",
                  width: `100%`,
                }}
              >
                <Line
                  style={{
                    padding: 10,
                  }}
                  data={chartDataByChannel}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        grid: {
                          color: "rgba(0, 0, 0, 0.1)",
                        },
                      },
                      x: {
                        grid: {
                          color: "rgba(0, 0, 0, 0.1)",
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                    },
                  }}
                />
              </div>
              <div
                style={{
                  height: "400px",
                  width: `100%`,
                  marginTop: "20px",
                }}
              >
                <Pie
                  style={{
                    padding: 10,
                  }}
                  data={calculatePercentages(messagesByChannel)}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                      datalabels: {
                        formatter: (value, context) => {
                          const total = context.dataset.data.reduce((sum, val) => sum + val, 0);
                          const percentage = ((value / total) * 100).toFixed(2);
                          return `${percentage}%`;
                        },
                        color: "#fff",
                        font: {
                          weight: "bold",
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Background>
      <Footer />
    </>
  );
};

export default Dashboard;