import React from "react";
import { Row, Col, Card } from "antd";
import { useIntl } from "react-intl";

const CardGridChannel = ({ messagesByChannel }) => {
  const intl = useIntl();

  const items = [
    {
      title: intl.formatMessage({
        id: "messages.web",
        defaultMessage: "Mensagens Web",
      }),
      value: messagesByChannel.Web,
    },
    {
      title: intl.formatMessage({
        id: "messages.widget",
        defaultMessage: "Mensagens Widget",
      }),
      value: messagesByChannel.Widget,
    },
    {
      title: intl.formatMessage({
        id: "messages.telegram",
        defaultMessage: "Mensagens Telegram",
      }),
      value: messagesByChannel.Telegram,
    },
    {
      title: intl.formatMessage({
        id: "messages.whatsapp",
        defaultMessage: "Mensagens Whatsapp",
      }),
      value: messagesByChannel.Whatsapp,
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      {items.map((item, index) => (
        <Col xs={24} sm={12} md={8} lg={6} key={index}>
          <Card
            style={{
              borderRadius: "12px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <h3
                style={{
                  marginBottom: "8px",
                  fontSize: "16px",
                  color: "#8c8c8c",
                }}
              >
                {item.title}
              </h3>
              <p style={{ fontSize: "24px", fontWeight: "bold", margin: 0 }}>
                {intl.formatNumber(isNaN(item.value) ? 0 : item.value, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CardGridChannel;
