import React, { useEffect, useState, useRef } from "react";
import { Layout, Menu, Button, Switch, Popover, Spin, Select } from "antd";
import {
  UploadOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  DashboardOutlined,
  CommentOutlined,
  FolderOutlined,
  FileOutlined,
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
  TeamOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import EvaLogo from "../../images/EVA.png";
import MaxxiLogo from "../../images/Logo_2024_Fundos_Claros.png";
import Mundo365 from "../../images/Mundo-365-Parceiro-Microsoft-do-Brasil.png";
import useChat from "../../stores/useChat";
import useAppSession from "../../stores/useAppSession";
import { useNavigate } from "react-router-dom";
import fileApi from "../../services/api/fileApi";
import FileUploadModal from "../FileUploadModal/FileUploadModal.jsx";
import FileListModal from "../FileListModal/FileListModal.jsx";
import YouTubeModal from "../YoutubeModal/YoutubeModal.jsx";
import { toast } from "react-toastify";
import userApi from "../../services/api/userApi.js";
import { useIntl } from "react-intl";
import '../common/css/Input.css'

const { Sider } = Layout;
const { Option } = Select;

const SidebarAntd = ({ children }) => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalUploadOpen, setIsModaUploadlOpen] = useState(false);
  const [isYouTubeModalOpen, setIsYouTubeModalOpen] = useState(false);
  const [youtubeUrl, setYouTubeUrl] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdminLoading, setIsAdminLoading] = useState(true);

  const {
    session,
    habilitaImagens,
    habilitaEmail,
    habilitaZendesk,
    habilitaContexto,
    isTyping,
    botTyping,
    engine,
    setSession,
    setHabilitaImagens,
    setHabilitaEmail,
    setHabilitaZendesk,
    setHabilitaContexto,
    setHabilitaVoz,
    setEngine,
    setShouldLog,
    setNewChat,
  } = useChat();

  const { clientId, sessionId, setSessionId, clearSession, isAdmin, setIsAdmin, locale, setLocale } = useAppSession();
  const navigate = useNavigate();

  const hasRenderedRef = useRef(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (hasRenderedRef.current) {
      return;
    }
    hasRenderedRef.current = true;

    const fetchUserData = async () => {
      if (isAdmin === null) {
        setIsAdminLoading(true);
        try {
          const response = await userApi.getCurrentUser(clientId);
          const isUserAdmin = response.data.user.admin;
          setIsAdmin(isUserAdmin);
          localStorage.setItem("isAdmin", isUserAdmin);
        } catch (error) {
          console.error("Erro ao buscar dados do usuário:", error);
        } finally {
          setIsAdminLoading(false);
        }
      } else {
        setIsAdminLoading(false);
      }
    };

    fetchUserData();
  }, [isAdmin, clientId, setIsAdmin]);

  const handleNewChat = async () => {
    setShouldLog(true);
    navigate("/");
    setSession("");
    setSessionId("");
    setNewChat(true);
  };

  const handleOpenDashboard = () => {
    navigate("/dashboard", { clientId, sessionId });
  };

  const handleOpenMessageboard = () => {
    navigate("/messageboard", { clientId, sessionId });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    fetchFiles();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchFiles = async () => {
    setLoading(true);
    try {
      const response = await fileApi.getFiles(clientId);

      if (response.status !== 200) {
        throw new Error(
          intl.formatMessage({
            id: "sidebar.errorLoadingFiles",
            defaultMessage: "Erro ao carregar arquivos",
          })
        );
      }

      const data = await response.data;
      const arrFiles = Object.entries(data).map(([file, date]) => ({ file, date }));
      setFiles(arrFiles);
    } catch (error) {
      console.error("Erro ao carregar arquivos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (files) => {
    if (files && files.length > 0) {
      try {
        await Promise.all(files.map((file) => fileApi.uploadFile(file, clientId)));
        let ret = await fileApi.processFile(clientId, session);
        if (ret.status === 200) {
          toast(
            intl.formatMessage({
              id: "sidebar.fileProcessedSuccess",
              defaultMessage: "Arquivo processado com sucesso!",
            })
          );
        }
      } catch (error) {
        console.error("Erro ao fazer upload dos arquivos:", error);
      }
    }
  };

  const deleteFile = async (fileId) => {
    try {
      await fileApi.deleteFile(clientId, fileId);
      setFiles(files.filter((file) => file.file !== fileId));
    } catch (error) {
      console.error("Erro ao deletar o arquivo:", error);
      toast.error(
        intl.formatMessage({
          id: "sidebar.errorDeletingFile",
          defaultMessage: "Ocorreu um erro ao deletar o arquivo.",
        })
      );
    }
  };

  const handleToggleImagens = (checked) => {
    setHabilitaImagens(checked);
  };

  const handleToggleEmail = (checked) => {
    setHabilitaEmail(checked);
  };

  const handleToggleZendesk = (checked) => {
    setHabilitaZendesk(checked);
  };

  const handleToggleContexto = (checked) => {
    setHabilitaContexto(checked);
  };

  const handleToggleVoz = (checked) => {
    setHabilitaVoz(checked);
  };

  const handleEngineChange = (value) => {
    setEngine(value);
  };

  const handleLogout = () => {
    clearSession();
  };

  const handleOpenProfile = () => {
    navigate("/user-profile");
  };

  const handleOpenWorkspace = () => {
    navigate("/workspace");
  };

  const handleOpenYouTube = () => {
    setIsYouTubeModalOpen(true);
  };
  const handleLanguageChange = (value) => {
    setLocale(value);
  };

  const profileContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {isAdmin && (
        <Button type="text" icon={<UserOutlined />} onClick={handleOpenProfile}>
          {intl.formatMessage({ id: "sidebar.profile", defaultMessage: "Perfil" })}
        </Button>
      )}
      <Button type="text" icon={<LogoutOutlined />} onClick={handleLogout}>
        {intl.formatMessage({ id: "sidebar.logout", defaultMessage: "Logout" })}
      </Button>
    </div>
  );

  if (isAdminLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onBreakpoint={() => {}}
        width={280}
        collapsedWidth={80}
        style={{ height: "100vh", position: "fixed", left: 0 }}
      >
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          {/* Top content */}
          <div>
            {/* Logo container and profile button */}
            <div
              className="logo-container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {!collapsed && (
                <>
                  <img
                    src={EvaLogo}
                    alt="Logo"
                    style={{ width: "40px", marginLeft: collapsed ? 0 : 20 }}
                  />
                  <span style={{ color: "white", fontSize: 20, fontWeight: 200 }}>
                    {intl.formatMessage({ id: "sidebar.assistant", defaultMessage: "Assistente" })}
                  </span>
                </>
              )}
              <Button
                type="primary"
                onClick={toggleCollapsed}
                style={{ marginLeft: collapsed ? "10px" : "0" }}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </Button>
            </div>
            {process.env.REACT_APP_AMBIENTE && !collapsed && (
              <span
                style={{
                  color: "white",
                  fontSize: 12,
                  fontWeight: 200,
                  marginLeft: 30,
                }}
              >
                {process.env.REACT_APP_AMBIENTE}
              </span>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Popover
                content={profileContent}
                trigger="click"
                placement="rightTop"
              >
                <Button
                  type="text"
                  style={{
                    fontSize: "16px",
                    color: "white",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    width: "auto",
                    margin: 10,
                  }}
                >
                  <UserOutlined style={{ fontSize: "20px" }} />
                  {!collapsed && intl.formatMessage({ id: "sidebar.profile", defaultMessage: "Perfil" })}
                </Button>
              </Popover>
            </div>

            {/* Menu */}
            <Menu
              theme="dark"
              mode="inline"
              items={[
                {
                  key: "1",
                  icon: <MessageOutlined />,
                  label: intl.formatMessage({ id: "sidebar.newChat", defaultMessage: "Novo Chat" }),
                  onClick: handleNewChat,
                  disabled: isTyping || botTyping,
                },
                ...(isAdmin
                  ? [
                      {
                        key: "2",
                        icon: <DashboardOutlined />,
                        label: intl.formatMessage({ id: "sidebar.dashboard", defaultMessage: "Dashboard" }),
                        onClick: handleOpenDashboard,
                      },
                      {
                        key: "3",
                        icon: <CommentOutlined />,
                        label: intl.formatMessage({ id: "sidebar.messages", defaultMessage: "Mensagens" }),
                        onClick: handleOpenMessageboard,
                      },
                      {
                        key: "4",
                        icon: <FolderOutlined />,
                        label: intl.formatMessage({ id: "sidebar.files", defaultMessage: "Arquivos" }),
                        children: [
                          {
                            key: "4-1",
                            icon: <UploadOutlined />,
                            label: intl.formatMessage({ id: "sidebar.uploadFiles", defaultMessage: "Carregar arquivos" }),
                            onClick: () => setIsModaUploadlOpen(true),
                          },
                          {
                            key: "4-2",
                            icon: <FileOutlined />,
                            label: intl.formatMessage({ id: "sidebar.showFiles", defaultMessage: "Mostrar arquivos" }),
                            onClick: handleOpenModal,
                          },
                        ],
                      },
                      {
                        key: "5",
                        icon: <SettingOutlined />,
                        label: intl.formatMessage({ id: "sidebar.settings", defaultMessage: "Configurações" }),
                        children: [
                          {
                            key: "5-1",
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  checked={habilitaImagens}
                                  onChange={handleToggleImagens}
                                  style={{ marginRight: "10px" }}
                                />
                                <span>{intl.formatMessage({ id: "sidebar.enableImageReading", defaultMessage: "Habilitar leitura de imagens" })}</span>
                              </div>
                            ),
                          },
                          {
                            key: "5-2",
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  checked={habilitaEmail}
                                  onChange={handleToggleEmail}
                                  style={{ marginRight: "10px" }}
                                />
                                <span>{intl.formatMessage({ id: "sidebar.enableEmail", defaultMessage: "Habilitar envio de e-mail" })}</span>
                              </div>
                            ),
                          },
                          {
                            key: "5-3",
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  checked={habilitaZendesk}
                                  onChange={handleToggleZendesk}
                                  style={{ marginRight: "10px" }}
                                />
                                <span>{intl.formatMessage({ id: "sidebar.enableZendesk", defaultMessage: "Habilitar zendesk" })}</span>
                              </div>
                            ),
                          },
                          {
                            key: "5-4",
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  checked={habilitaContexto}
                                  onChange={handleToggleContexto}
                                  style={{ marginRight: "10px" }}
                                />
                                <span>{intl.formatMessage({ id: "sidebar.contextOnly", defaultMessage: "Somente contexto" })}</span>
                              </div>
                            ),
                          },
                          {
                            key: "5-5",
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginRight: "10px" }}>
                                  {intl.formatMessage({ id: "sidebar.engine", defaultMessage: "Engine" })}
                                </span>
                                <Select
                                  className="dark-select"
                                  defaultValue={engine}
                                  style={{ width: 120 }}
                                  onChange={handleEngineChange}
                                >
                                  <Option value="azure">Azure</Option>
                                  <Option value="openai">OpenAI</Option>
                                </Select>
                              </div>
                            ),
                          },
                          {
                            key: "5-6",
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ marginRight: "10px", }}>
                                  {intl.formatMessage({ id: "sidebar.language", defaultMessage: "Idioma" })}
                                </span>
                                <Select
                                  className="dark-select"
                                  defaultValue={locale}
                                  style={{ width: 120,  }}
                                  onChange={handleLanguageChange}
                                >
                                  <Option value="pt">Português</Option>
                                  <Option value="en">Inglês</Option>
                                </Select>
                              </div>
                            ),
                          },
                        ],
                      },
                      {
                        key: "6",
                        icon: <TeamOutlined />,
                        label: intl.formatMessage({ id: "sidebar.workspace", defaultMessage: "Workspace" }),
                        onClick: handleOpenWorkspace,
                      },
                      {
                        key: "8",
                        icon: <YoutubeOutlined />,
                        label: intl.formatMessage({ id: "sidebar.youtube", defaultMessage: "YouTube" }),
                        onClick: handleOpenYouTube,
                      },
                    ]
                  : []),
              ]}
              style={{ flex: 1, overflowY: "auto" }}
            />

            {!collapsed && (
              <div
                style={{
                  marginTop: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "white",
                  margin: "10px",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={MaxxiLogo}
                  alt="Maxxi Logo"
                  style={{ height: "30px", marginBottom: "10px" }}
                />
                <img
                  src={Mundo365}
                  alt="Mundo 365 Logo"
                  style={{ height: "50px" }}
                />
              </div>
            )}
          </div>

          {/* Modals */}
          <FileUploadModal
            isOpen={isModalUploadOpen}
            onClose={() => setIsModaUploadlOpen(false)}
            onFileUpload={handleFileUpload}
          />
          <FileListModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            files={files}
            onDeleteFile={deleteFile}
          />
          <YouTubeModal
            isOpen={isYouTubeModalOpen}
            onClose={() => setIsYouTubeModalOpen(false)}
            youtubeUrls={youtubeUrl}
            setYouTubeUrls={setYouTubeUrl}
          />
        </div>
      </Sider>
      <div style={{ marginLeft: collapsed ? 80 : 280, width: "100%" }}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Spin size="large" />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default SidebarAntd;
