import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import mediaApi from "../../services/api/mediaApi";
import useAppSession from "../../stores/useAppSession";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

const FooterButtons = ({ onClose, onConfirm, intl, loading }) => (
  [
    <Button key="back" onClick={onClose} disabled={loading}>
      {intl.formatMessage({ id: 'cancel' })}
    </Button>,
    <Button key="submit" type="primary" onClick={onConfirm} loading={loading}>
      {intl.formatMessage({ id: 'confirm' })}
    </Button>,
  ]
);

const YouTubeModal = ({ isOpen, onClose }) => {
  const [newUrl, setNewUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const { sessionId } = useAppSession();
  const intl = useIntl();
  
  const handleConfirm = async () => {
    if (newUrl.trim() !== "") {
      setLoading(true);
      try {
        await mediaApi.uploadYoutube({ url: newUrl, sessionid: sessionId });
        toast.success(intl.formatMessage({ id: 'youtube.add.sucess' }));
        onClose();
      } catch (error) {
        console.error("Erro ao fazer upload da URL do YouTube:", error);
        toast.error(intl.formatMessage({ id: 'youtube.add.error' }));
      } finally {
        setLoading(false);
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'youtube.add.url' })}
      open={isOpen}
      onCancel={onClose}
      onOk={handleConfirm}
      footer={<FooterButtons onClose={onClose} onConfirm={handleConfirm} intl={intl} loading={loading} />}
    >
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder={intl.formatMessage({ id: 'youtube.enter.url' })}
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default YouTubeModal;
