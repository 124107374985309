import React from "react";
import { Row, Col, Card } from "antd";
import { useIntl } from "react-intl";

const CardGrid = ({
  totalTokens,
  totalFilesTokens,
  lastMonthTokens,
  totalMessages,
  totalFilesSize,
  totalMessagesSize,
  averageTokens,
}) => {
  const intl = useIntl();

  const cardItems = [
    {
      title: intl.formatMessage({ id: "cardGrid.tokensMessages", defaultMessage: "Tokens mensagens" }),
      value: totalTokens,
    },
    {
      title: intl.formatMessage({ id: "cardGrid.tokensFiles", defaultMessage: "Tokens arquivos" }),
      value: totalFilesTokens,
    },
    {
      title: intl.formatMessage({ id: "cardGrid.lastMonthTokens", defaultMessage: "Tokens do último mês" }),
      value: lastMonthTokens,
    },
    {
      title: intl.formatMessage({ id: "cardGrid.totalTokens", defaultMessage: "Total tokens" }),
      value: totalTokens + totalFilesTokens,
    },
    {
      title: intl.formatMessage({ id: "cardGrid.messagesInPeriod", defaultMessage: "Mensagens no período" }),
      value: totalMessages * 2,
    },
    {
      title: intl.formatMessage({ id: "cardGrid.filesSentKB", defaultMessage: "Arquivos enviados (kB)" }),
      value: totalFilesSize,
      precision: 1,
    },
    {
      title: intl.formatMessage({ id: "cardGrid.averageTokensPerMonth", defaultMessage: "Média Tokens/Mês" }),
      value: averageTokens,
    },
    {
      title: intl.formatMessage({ id: "cardGrid.messagesSentKB", defaultMessage: "Mensagens enviadas (kB)" }),
      value: totalMessagesSize,
      precision: 1,
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      {cardItems.map((item, index) => (
        <Col xs={24} sm={12} md={8} lg={6} key={index}>
          <Card
            style={{
              borderRadius: "12px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <h3
                style={{
                  marginBottom: "8px",
                  fontSize: "16px",
                  color: "#8c8c8c",
                }}
              >
                {item.title}
              </h3>
              <p style={{ fontSize: "24px", fontWeight: "bold", margin: 0 }}>
                {isNaN(item.value)
                  ? 0
                  : Number.isInteger(item.value)
                  ? item.value
                  : item.value.toFixed(2).replace(".", ",")}
              </p>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CardGrid;
